<template>
  <div class="home">
    <div class="on-chain">
      <div class="z">
        On-Chain Culture
      </div>
      <img src="../assets/images/Vector.svg" alt="">
    </div>
    <div class="text-box">
      <div class="title">
        POP
      </div>
      <div class="sub-title">
        designed for a mass audiences
      </div>
    </div>
    <div class="text-box">
      <div class="title">
        FREE
      </div>
      <div class="sub-title">
        break rigid rules
      </div>
    </div>
    <div class="text-box">
      <div class="title">
        RELATABLE
      </div>
      <div class="sub-title">
        relevant to people
      </div>
    </div>
  </div>
  <div class="home-footer">
    <div class="home-img-box">
      <div class="item1">
        <img src="@/assets/images/bird.png" alt="">
      </div>
      <div class="item2">
        <img src="@/assets/images/cat.png" alt="">
      </div>
      <div class="item3">
        <img src="@/assets/images/lion.png" alt="">
      </div>
      <div class="item4">
        <img src="@/assets/images/dog.png" alt="">
      </div>
      <div class="item5">
        <img src="@/assets/images/horse.png" alt="">
      </div>
    </div>
    <div class="home-footer-text">
      <p>Bitpets Season 1</p>
      <p>Sept.9th</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  padding: 10px 180px 0px;
  color: #000;
  font-size: 34.64px;
  font-weight: 400;
  transform: translateY(180px);
}
.on-chain {
  position: relative;
  width: 742px;
  border: 7px solid #000;
  padding: 15.17px 0 24.12px 22.24px;
  background-color: #fff;
  margin-bottom: 12px;
}
.on-chain .z {
  z-index: 2;
}
.on-chain img {
  position: absolute;
  bottom: -51px;
  right: 85px;
}
.text-box {
  padding-left: 10px;
  margin-bottom: 51px;
}
.title {
  width: 347.72px;
  color: #000;
  font-size: 40.01px;
  font-weight: 400;
  border-bottom: 2px solid #000;

}
.sub-title {
  color: #000;
  font-size: 25.2px;
  font-weight: 400;
}
.home-footer {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 25.2px;
  font-weight: 400;
}
.home-img-box {
  transform: translateX(40px);
  display: flex;
  align-items: flex-end;
}
.item1 img {
  position: relative;
  z-index: 1;
  transform: translateY(30px) translateX(270px);
}
.item2 img {
  position: relative;
  z-index: 4;
  transform: translateY(40px) translateX(210px);
}
.item3 img {
  position: relative;
  z-index: 3;
  transform: translateY(46px) translateX(145px);
}
.item4 img {
  position: relative;
  z-index: 4;
  transform: translateY(57px) translateX(17px);
}
.item5 img {
  position: relative;
  z-index: 5;
  transform: translateY(32px) translateX(-167px);
}
.home-footer-text {
  position: relative;
  transform: translateY(111px) translateX(-32px);
}
</style>
